import React from "react"
import Badge from "../images/badge.svg"
import Thumbs from "../images/thumbs.svg"
import Money from "../images/money.svg"
import Pole from "../images/pole.svg"

const WhyChooseUs = () => {
  return (
    <div className="px-2 md:p-12 text-center w-full">
      <h2 className="text-2xl mb-6 uppercase">Why Choose Us?</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-stretch">
        <div className="flex flex-col justify-between">
          <img alt="Free quote icon" className="h-16 mb-3" src={Thumbs} />
          <p className="uppercase text-sm">Free Quote</p>
        </div>
        <div className="flex flex-col justify-between">
          <img
            alt="Badge of experience icon"
            className="h-16 mb-3"
            src={Badge}
          />
          <p className="uppercase text-sm">Years of experience</p>
        </div>
        <div className="flex flex-col justify-between">
          <img alt="Pole cleaning icon" className="h-16 mb-3" src={Pole} />
          <p className="uppercase text-sm">Pure water technology</p>
        </div>
        <div className="flex flex-col justify-between">
          <img
            alt="Affordable pricing icon"
            className="h-16 mb-3"
            src={Money}
          />
          <p className="uppercase text-sm">Affordable Prices</p>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
